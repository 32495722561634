<template>
  <div class="products category-med white px-2 px-md-6">
    <Breadcrumb
      :items="category.navigationTypeId != 0 ? breadcrumbs : breadcrumb"
    />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container class="py-0">
      <CategoryTitle
        :category="category"
        :selectedCategory="selectedCategory"
      />
      <v-chip-group show-arrows v-if="category.children">
        <v-chip
          v-for="subCategory in category.children"
          :key="subCategory.categoryId"
          label
          link
          exact-active-class="secondary"
          large
          :to="
            category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }
          "
          class="mr-2 mb-2 grey lighten-1"
        >
          {{ subCategory.name }}
        </v-chip>
      </v-chip-group>
    </v-container>
    <v-container>
      <ProductListGrid
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="categoryId || category.categoryId"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
      />
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import analyticsServiceGTM from "@/commons/service/analytics/analyticsServiceGTM";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "CategoryLast",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  data() {
    return { count: -1 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    const categories = Object.values(
      analyticsServiceGTM.getCategoryPath(this.category.categoryId)
    );
    const leafCategory = categories[categories.length - 1];
    const parentCategory = categories[categories.length - 2];
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.$t("meta.category.thirdLevel.title", [leafCategory, parentCategory])
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      this.$t("meta.category.thirdLevel.description", [leafCategory])
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            this.category.metaData?.category_info?.HEADER_IMAGE ||
            this.category.metaData?.category_info?.DESCRIPTION_IMAGE ||
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Zona.eu"
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
